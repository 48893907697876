<template>
  <div class="footer">
      <div class="one">
          <img class="img" src="../../assets/img/foot_1.jpg" alt="">
          <div class="right">
              <div class="right_one">5G时代下企业形象塑造与营销</div>
              <div class="right_two">我们一起面对</div>

              <div  style="margin-top:80px;display:flex">
                  <div>
                      <div class="dis">
                          <img class="img_68" src="../../assets/img/foot_2.png" alt="">
                          <div style="margin-left:16px">
                              <div class="item_1"> 商务咨询热线</div>
                              <div class="item_2"> 400-678-0987</div>
                          </div>
                      </div>

                      <div class="dis" style="margin-top:82px">
                          <img class="img_68" src="../../assets/img/foot_3.png" alt="">
                          <div style="margin-left:16px">
                              <div class="item_1"> 电话咨询</div>
                              <div class="item_2"> 0519-88893933</div>
                              <div class="item_2 item_2_2"> 181-1234-1413</div>
                          </div>
                      </div>

                      <div class="dis" style="margin-top:82px">
                          <img class="img_68" src="../../assets/img/foot_6.png" alt="">
                          <div style="margin-left:16px">
                              <div class="item_1" style="color: #CB0012;"> 在线咨询</div>
                              <div class="item_2" style="color: #CB0012;"> 08:00-22:00</div>
                          </div>
                      </div>
                  </div>
                  <div  style="margin-left:109px;display:flex">
                      <img class="img_68" src="../../assets/img/foot_3.png" alt="">
                      <div style="margin-left:16px">
                          <div class="item_1"> 微信咨询</div>
                          <img class="img_247" src="../../assets/img/foot_5.png" alt="">
                          <div class="item_3">微信扫一扫 添加好友</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="w">
          <div class="dis" style="margin-top:80px;justify-content: space-between">
              <div class="dis" v-for="(item, index) in address" :key="index">
                  <img class="img_68" src="../../assets/img/foot_7.png" alt="">
                    <div style="margin-left:33px">
                    <div class="item_1">{{ item.name }}</div>
                    <div class="item_4">{{ item.address }}</div>
                  </div>
              </div>

          </div>
      </div>
      <div class="three">
          <div class="w">
              <div class="three_top">
                  <div class="three_nav" @click="toPage(0)">首页</div>
                  <div class="three_line"></div>
                  <div class="three_nav" @click="toPage(4)">关于我们</div>
                  <div class="three_line"></div>
                  <div class="three_nav" @click="toPage(1)">服务</div>
                  <div class="three_line"></div>
                  <div class="three_nav" @click="toPage(2)">案例</div>
                  <div class="three_line"></div>
                  <div class="three_nav" @click="toPage(3)">智库/资讯</div>
              </div>
              <div class="dis item_1 three_center">
                  <div style="margin-right:78px">服务热线：400-678-0987</div>
                  <div style="margin-right:78px">0519-88893933</div>
                  <div>18112341413</div>
              </div>
            <div class="line"></div>
            <div class="dis foot_word">
                <div class="foot_word_item">版权所有©2009常州大海网络有限公司</div>
              <div class="foot_word_item"><a href="https://ythzxfw.miit.gov.cn/index" target="_black" class="word_a">苏B2-20230047</a></div>
                <div class="foot_word_item"><a href="https://beian.miit.gov.cn/" target="_black" class="word_a">苏ICP备2022037602号-1 </a></div>
                <div class="foot_word_item dis"><img src="@/assets/img/beian.png" style="float:left;"/><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32040202000571" target="_black" class="word_a">苏公网安备 32040202000571号</a></div>

            </div>
            <div class="dis foot_word" style="margin-top: 30px;">业务覆盖长三角全域：常州｜苏州｜无锡｜上海｜南京｜杭州｜南通｜泰州｜盐城｜淮安｜连云港｜徐州｜嘉兴｜湖州｜宁波｜绍兴｜台州｜合肥...</div>
            <div class="foot_word" style="margin-top: 30px;margin-left:88px">
              友情链接：
              <span v-for="(item, index) in friendLinks" :key="index">
                <a :href="$global.fullImg(item.url)" target="_black" class="word_a" >{{item.name}}
                </a>
              </span>
            </div>
            <div class="bottom">
                <!-- <a href="http://www.trillion.net.cn" target="_black"> -->
                <a href="http://www.wanyi.net.cn" target="_black">
                    <img class="img_32" src="../../assets/img/img_1.png" alt="">
                </a>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
  import { friendLink, address } from "../../js/api";

  export default {
    name:'Footer',
    data() {
        return {
          friendLinks:[],
          address:[]
        }
    },
    mounted() {
      this.getFriendLinks()
      this.getAddress()
    },
    methods:{
        async getAddress(){
          const res = await address()
          this.address = res.data
        },
        async getFriendLinks(){
          const res = await friendLink()
          this.friendLinks = res.data
        },
        toPage(num){
            localStorage.setItem('nav',num)
            let path={
                0:'/',//首页
                1:'/service',//服务
                2:'/case',//案例
                3:'/news', //智库/资讯
                4:'/about',//关于我们
            }
            this.$router.push({path:path[num]})
        },
    }
}
</script>

<style lang="scss" scoped>

.img_68{
    width: .85rem;
    height: .85rem;
}

.img_247{
    width: 3.0875rem;
    height: 3.0875rem;
    margin-top: .025rem;
}

.img_32{
    width: .4rem;
    height: .4rem;
}

.one{
    display: flex;
    min-width: 22.325rem;
    padding-top: .9rem;

    .img{
        width: 11.5rem;
        height: 8.4rem;
    }

    .right{
        margin-left: .85rem;


        .right_one{
            font-size: .325rem;
            line-height: .4625rem;
            color: #CB0012;
        }

        .right_two{
            font-size: .85rem;
            line-height: 1.1875rem;
            color: #CB0012;
        }


    }
}



.item_1{
        font-size: .325rem;
        font-weight: 300;
        line-height: .4625rem;
        color: rgba(0,0,0,0.98);
}

.item_2{
    font-size: .4rem;
    font-weight: 800;
    line-height: .5625rem;
    color: rgba(0,0,0,0.98);
    letter-spacing: .025rem;
}
.item_2_2{
  letter-spacing: .03rem;
}
.item_3{
    font-size: .225rem;
    line-height: .3125rem;
    color: rgba(0,0,0,0.98);
    margin-top: .2125rem;
    margin-left: .45rem;

}

.item_4{
    width: 4.6375rem;
    height: .925rem;
    font-size: .325rem;
    line-height: .4625rem;
    color: rgba(0,0,0,0.98);
    letter-spacing: .025rem;
    margin-top: .15rem;
}

.three{
    width: 100%;
    min-width: 20rem;
    height: 6rem;
    padding-top: .625rem;
    background: rgb(34,34,34);
    margin-top: 1rem;
    box-sizing: border-box;

    .three_top{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .25rem;
        color: #FFFFFF;
        opacity: 0.4;

        .three_nav{
            //  width: 100px;
             letter-spacing: .025rem;
             line-height: .35rem;
             text-align: center;
             cursor: pointer;
        }

        .three_line{
            width: 0;
            height: .25rem;
            border: .0125rem solid #FFFFFF;
            background-color: #FFFFFF;
            margin-left: .375rem;
            margin-right: .375rem;
        }
    }

    .three_center{
        justify-content: center;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.4;
        margin-top: .3rem;
        margin-bottom: .6rem;
    }
}

.line{
    width: 100%;
    min-width: 20rem;
    height: .0125rem;
    background-color: #FFFFFF;
    opacity: 0.2;
}

.foot_word{
    justify-content: center;
    font-size: .25rem;
    line-height: .35rem;
    color: #FFFFFF;
    letter-spacing: .025rem;
    opacity: 0.4;
    margin-top: .675rem;


    .foot_word_item{
        margin-right: .25rem;
    }
}
.word_a{
    font-size: .25rem;

    color: #FFFFFF;
    letter-spacing: .025rem;
}

.bottom{
    display: flex;
    justify-content: center;
}
</style>
